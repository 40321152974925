.follow-search{
    height: 70px;
    border-bottom: 1px solid #E4E7ED;
    margin-bottom: 20px;
    padding-top:10px
}
.chat-user-list1{
    list-style: none
}
.chat-li1{
    display: flex;
    margin-bottom: 20px;
    margin-top:15px
}
.search1{
    width:400px
}
.flex-display1{
    display:flex;
    width:550px
}
.follow-btn1{
    padding-left:18px;
    padding-right:18px;
    width:105px
}
.modal-header .close{
    margin: 0px
}
.modal .modal-footer{
    border:none
}