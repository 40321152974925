/*========================================================
        DARK LAYOUT
=========================================================*/
#dashboard-analytics .img-left {
  width: 200px;
  position: absolute;
  top: 0;
  left: 0; }

#dashboard-analytics .img-right {
  width: 175px;
  position: absolute;
  top: 0;
  right: 0; }

#dashboard-analytics .bg-analytics {
    background: linear-gradient(118deg, #47a547
, #86ca86);
}
  #dashboard-analytics .bg-analytics .avatar {
    margin-bottom: 2rem; }

#dashboard-analytics table tr th:first-child, #dashboard-analytics table tr td:first-child {
  padding-left: 2rem; }

#dashboard-analytics table tr th:last-child, #dashboard-analytics table tr td:last-child {
  padding-right: 2rem; }

#dashboard-analytics table td {
  padding: .75rem; }

@media only screen and (max-width: 576px) {
  #dashboard-analytics .img-left, #dashboard-analytics .img-right {
    width: 140px; } }
