.chat-user-list {
  margin-left: -35px;
  height: 300px;
  overflow-y: auto;
}
.chat-search {
  width: 202px;
  margin-left: -13px;
}
.chat-selected-user {
  margin-left: -31px;
  margin-right: -2px;
  margin-top: -6px;
  padding-top: 10px;
  padding-bottom: 6px;

  font-weight: bold;
  font-size: 19px;

  border-bottom: 4px solid #e4e7ed;
}
.chat-left-side {
  border-right: 10px solid #e4e7ed;
  width: 310px;
}
.follow-search {
  border-bottom: 10px solid #e4e7ed;
  margin-left: 14px;
}

.left-chat {
  color: green;
  background: #e8e8e8;
  padding: 5px 15px;
  border-radius: 5px;
  width: max-content;
  max-width: 80%;
}
.right-chat {
  color: green;
  color: green;
  background: #e8e8e8;
  padding: 5px 15px;
  border-radius: 5px;
  width: max-content;
  max-width: 80%;
  margin-left: auto;
}
.chat-box {
  /* height: 280px;
  display: flex;
  flex-direction: column;
  padding: 15px; */
  display: flex;
    flex-direction: column;
    padding: 15px;
    overflow-y: scroll;
    height: 400px;
}
.chat-user-list .avatar img {
  height: 30px;
  width: 30px;
  object-fit: cover;
}
.no-chat-div {
  text-align: center;
  margin-top: 30%;
}
@media screen and (max-width: 767px) {
  .chat-bred {
    margin-top: -10px;
  }
  .chat-user-list .avatar img {
    height: 30px;
    width: 30px;
    object-fit: cover;
  }
  .chat-selected-user {
    border-top: 10px solid #e4e7ed;
    margin-left: 0;
  }
  .no-chat-div {
    margin: 50px 0;
  }
  .chat-left-side {
    width: 100% !important;
    border-right: 0;
  }
  .follow-search {
    width: calc(100% - 27px);
  }
  .chat-box {
    height: 480px;
  }
  .has-icon-left .form-control {
    margin-left: 0;
    width: 85% !important
    /* width: calc(100% - 60px) !important; */
  }
}
