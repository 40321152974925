.col-10 {
    padding-top: 20px !important;
}
.text-secondary {
    color: #2396FB !important;
}
.bg-gray-100 {
    background-color: #F5F8F8 !important;
}
.mb-3, .my-3 {
    margin-bottom: 1rem !important;
}
.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}  
.text123 {
    text-align: right !important;
    width: 9%;
}
.theme1 .tracking-details-time {
    -ms-flex: 100px 0 0px;
    flex: 100px 0 0;
}
.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
    
}

.pow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    
}

.por{
    width: 90%;
    margin-left: 5%;
}

.pol-1, .pol-2, .pol-3, .pol-4, .pol-5, .pol-6, .pol-7, .pol-8, .pol-9, .pol-10, .pol-11, .pol-12, .pol, .pol-auto, .pol-sm-1, .pol-sm-2, .pol-sm-3, .pol-sm-4, .pol-sm-5, .pol-sm-6, .pol-sm-7, .pol-sm-8, .pol-sm-9, .pol-sm-10, .pol-sm-11, .pol-sm-12, .pol-sm, .pol-sm-auto, .pol-md-1, .pol-md-2, .pol-md-3, .pol-md-4, .pol-md-5, .pol-md-6, .pol-md-7, .pol-md-8, .pol-md-9, .pol-md-10, .pol-md-11, .pol-md-12, .pol-md, .pol-md-auto, .pol-lg-1, .pol-lg-2, .pol-lg-3, .pol-lg-4, .pol-lg-5, .pol-lg-6, .pol-lg-7, .pol-lg-8, .pol-lg-9, .pol-lg-10, .pol-lg-11, .pol-lg-12, .pol-lg, .pol-lg-auto, .pol-xl-1, .pol-xl-2, .pol-xl-3, .pol-xl-4, .pol-xl-5, .pol-xl-6, .pol-xl-7, .pol-xl-8, .pol-xl-9, .pol-xl-10, .pol-xl-11, .pol-xl-12, .pol-xl, .pol-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}


.track-progress {
    margin: 0;
    padding: 10px 10px 0 10px;
    background: white;
    height: 80px;
    border-radius: 10px;
    -moz-box-shadow: 0 4px 6px -5px black;
    -webkit-box-shadow: 0 4px 6px -5px black;
    box-shadow: 0 4px 6px -5px black;
  }



  .progress-bar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #303F9F;
    transition: width 0.6s ease;
}

.progress2 {
    display: flex;
    height: 1.25rem;
    overflow: hidden;
    font-size: .705rem;
    background-color: #4caf50;
    border-radius: 0.6rem;
    box-shadow: inset 0 0.1rem 0.1rem rgb(0 0 0 / 10%);
}

.tracker-progress-bar-with-dots {
    margin-bottom: 90px;
    position: relative;
}
.tracker-progress-bar-with-dots .progress {
    margin-bottom: 19px;
    height: 12px;
    border-radius: 6px;
}
.progress {
    display: -ms-flexbox;
    display: flex;
    height: 1.25rem;
    overflow: hidden;
    font-size: .705rem;
    background-color: #E4ECEF;
    border-radius: 0.6rem;
    box-shadow: inset 0 0.1rem 0.1rem rgb(0 0 0 / 10%);
}
.tracker-progress-bar-with-dots .progress .progress-bar {
    box-shadow: inset 0 2px 2px rgb(0 0 0 / 15%);
}
.bg-success {
    background-color: #5cb85c !important;
}
.tracker-progress-bar-with-dots .success .dot {
    border-color: #5cb85c;
}
.tracker-progress-bar-with-dots .dot {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #fff;
    border-width: 9px;
    border-style: solid;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 40%);
    top: 0;
    margin-left: -20px;
    margin-top: -14px;
}
.tracker-progress-bar-with-dots .state-0 {
    position: absolute;
    left: 0;
}
.tracker-progress-bar-with-dots .state-2 {
    position: absolute;
    left: 66%;
}
.tracker-progress-bar-with-dots .state-3 {
    position: absolute;
    left: 100%;
}
.tracker-progress-bar-with-dots .current-state {
    font-weight: 700;
}

.tracker-progress-bar-with-dots .past-state {
    color: #899DA3;
}
.tracker-progress-bar-with-dots .state-label {
    width: 90px;
    margin-left: -45px;
    text-align: center;
}
.tracker-progress-bar-with-dots .state-0 {
    position: absolute;
    left: 0;
}
.tracker-progress-bar-with-dots .state-1 {
    position: absolute;
    left: 33%;
}
.track-progress em {
    display: none;
    font-weight: 700;
    padding-left: 0.75em;
  }
@media (max-width: 800px) {
    .track-progress li {
      font-size: 0.7em;
    }
  }
  @media (min-width: 992px){
  .col-lg-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
  }
  }
  @media (min-width: 768px){
  .col-md-8 {
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
  }
  }
  

  @media (max-width: 768px){
  .text123 {
    text-align: right !important;
    width: 66px
}
    }