.location-textbox-search input {
  height: 36px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  padding: 0 10px;
}

.take-a-photo-main-body {
  max-width: 700px;
  margin: auto;
}

.take-a-photo-main-body .owl-nav {
  position: absolute;
  top: 18px;
  left: -25px;
  right: -20px;
}

.take-a-photo-main-body .owl-nav button.owl-prev {
  left: 0;
}

.take-a-photo-main-body .owl-nav button.owl-next {
  right: 0;
}

.take-a-photo-main-body .owl-nav button {
  color: #000 !important;
  font-size: 40px !important;
  position: absolute;
  outline: none !important;
  box-shadow: none !important;
  background: transparent !important;
}

#npstep2,
#npstep3 {
  display: none;
}

.ddd {
  position: relative;
  width: 50%;
  text-align: center;

  display: block;
  margin-left: 15em;
}

@media screen and (max-width: 767px) {
  .card-img-top {
    height: 62px;
    padding: 8px;
  }

  .sa-text-first {
    padding-left: 0;
    padding-right: 0;
    margin: auto;
    /* flex-wrap: wrap; */
    justify-content: flex-end;
    padding-bottom: 10px;
  }

  .location-textbox-search {
    /* margin-bottom: 10px; */
    max-width: 100% !important;
  }

  .card-img-top {
    height: 110px;
  }

  .location-textbox-search input {
    height: 20px;
    margin-right: -5px;
  }

  .sa-text-first select {
    width: 100%;
  }

  /* .trigger-div {
        width: 40px;
        height: 40px;
    } */
  .take-a-photo-main-body .owl-nav {
    /* top: -16px; */
    top: 24px;
  }

  .delete-button {
    position: absolute !important;
    right: -3px !important;
    border: 0;
    background: transparent;
    width: 26px !important;
    font-size: 14px !important;
    top: 1px !important;
  }

  .ddd {
    position: relative;
    padding-top: 10%;

    width: 50%;
    text-align: center;
    display: block;
    margin-left: 10em;
  }
}

.delete-button {
  position: absolute;
  right: 7px;
  border: 0;
  background: transparent;
  width: 26px;
  font-size: 20px;
  top: 4px;
}

.card-delete-button {
  position: relative;
}

.star-flexs {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.gl-new-icon {
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0 5px;
  color: #fff;
  position: absolute;
}

.gl-new-icon.dlt {
  background: #f44336;
  right: 10px;
  top: 10px;
}
.gl-new-icon.cpy {
  background: #ffc107;
  right: 75px;
  top: 10px;
}
.gl-new-icon.edit {
  background: #00bcd4;
  right: 43px;
  top: 10px;
}
@media screen and (max-width: 767px) {
  .gl-new-icon {
    height: 20px;
    width: 20px;
    font-size: 10px;
  }
  
}