/*========================================================
        DARK LAYOUT
=========================================================*/
.chat-application .content-area-wrapper .content-right .content-wrapper {
  padding : 0;
}

.chat-application .content-area-wrapper {
  border : 1px solid #DAE1E7;
  border-radius : 0.25rem;
}

.chat-application .chat-profile-sidebar {
  border-left : 1px solid #E4E7ED;
  height : calc(100vh - 13rem);
  height : calc(var(--vh, 1vh) * 100 - 13rem);
  width : 400px;
  border-radius : 0.25rem;
  border-top-left-radius : 0;
  border-bottom-left-radius : 0;
  margin : 0;
  background-color : #FFFFFF;
  position : fixed;
  -webkit-transform : translateX(110%);
      -ms-transform : translateX(110%);
          transform : translateX(110%);
  -webkit-transition : all 0.3s ease;
          transition : all 0.3s ease;
  z-index : 6;
}
.chat-application .chat-profile-sidebar.show {
  -webkit-transform : translateX(0);
      -ms-transform : translateX(0);
          transform : translateX(0);
  -webkit-transition : all 0.3s ease;
          transition : all 0.3s ease;
}
.chat-application .chat-profile-sidebar .chat-profile-header {
  display : -webkit-box;
  display : -webkit-flex;
  display : -ms-flexbox;
  display :         flex;
  text-align : center;
  border-bottom : 1px solid rgba(0, 0, 0, 0.06);
}
.chat-application .chat-profile-sidebar .chat-profile-header .header-profile-sidebar {
  margin : 2rem auto 0.5rem;
}
.chat-application .chat-profile-sidebar .chat-profile-header .avatar {
  margin-bottom : 1.25rem;
}
.chat-application .chat-profile-sidebar .chat-profile-header .close-icon {
  position : absolute;
  top : 14px;
  left : 13px;
  font-size : 1.75rem;
  cursor : pointer;
}
.chat-application .chat-profile-sidebar .profile-sidebar-area .scroll-area {
  padding : 2rem;
  height : calc(100vh - 24.25rem);
  height : calc(var(--vh, 1vh) * 100 - 24.25rem);
  position : relative;
}

.chat-application .sidebar-content {
  border-left : 1px solid #E4E7ED;
  height : calc(100vh - 13rem);
  height : calc(var(--vh, 1vh) * 100 - 13rem);
  width : 400px;
  border-radius : 0.25rem;
  border-top-left-radius : 0;
  border-bottom-left-radius : 0;
  margin : 0;
  box-shadow : none;
}
.chat-application .sidebar-content .sidebar-close-icon {
  position : absolute;
  left : 0.25rem;
  top : 0.25rem;
  font-size : 1.25rem;
  z-index : 1;
  cursor : pointer;
  visibility : hidden;
}
.chat-application .sidebar-content .chat-fixed-search {
  position : fixed;
  width : 400px;
  border-bottom : 1px solid #E4E7ED;
  padding : 0.65rem;
}
.chat-application .sidebar-content .chat-fixed-search .sidebar-profile-toggle .avatar {
  display : inline-table;
  width : calc(32px + 8px);
}
.chat-application .sidebar-content .chat-fixed-search input.form-control {
  padding : 0.9rem 3rem 0.9rem 1rem;
  height : calc(1.25em + 1.4rem + 4px);
}
.chat-application .sidebar-content .chat-fixed-search .form-control-position {
  top : 5px;
}
.chat-application .sidebar-content .chat-fixed-search .form-control-position i {
  right : 9px;
}
.chat-application .sidebar-content .chat-user-list {
  height : calc(100% - 5rem);
  margin-top : 5rem;
  width : 400px;
}
.chat-application .sidebar-content .chat-user-list ul {
  padding-right : 0;
  margin-bottom : 0;
}
.chat-application .sidebar-content .chat-user-list li {
  display : -webkit-box;
  display : -webkit-flex;
  display : -ms-flexbox;
  display :         flex;
  -webkit-box-pack : justify;
  -webkit-justify-content : space-between;
  -ms-flex-pack : justify;
          justify-content : space-between;
  -webkit-box-align : center;
  -webkit-align-items : center;
  -ms-flex-align : center;
          align-items : center;
  padding : 1.322rem 0.85rem;
  margin-left : 1px;
}
.chat-application .sidebar-content .chat-user-list li:not(:first-child) {
  border-top : 1px solid #E4E7ED;
}
.chat-application .sidebar-content .chat-user-list li .user-chat-info {
  width : 100%;
  display : -webkit-box;
  display : -webkit-flex;
  display : -ms-flexbox;
  display :         flex;
  -webkit-box-pack : justify;
  -webkit-justify-content : space-between;
  -ms-flex-pack : justify;
          justify-content : space-between;
  overflow : hidden;
}
.chat-application .sidebar-content .chat-user-list li .contact-info {
  width : calc(100vw - (100vw - 100%) - 1rem - 50px);
  margin-top : 0.3rem;
}
.chat-application .sidebar-content .chat-user-list li .contact-info .truncate {
  margin : 0;
}
.chat-application .sidebar-content .chat-user-list li:hover {
  cursor : pointer;
  background : #EEEEEE;
}
.chat-application .sidebar-content .chat-user-list li.active {
  background : -webkit-linear-gradient(208deg, #7367F0, rgba(115, 103, 240, 0.7));
  background :         linear-gradient(-118deg, #7367F0, rgba(115, 103, 240, 0.7));
  box-shadow : 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  color : #FFFFFF;
}
.chat-application .sidebar-content .chat-user-list li.active h1, .chat-application .sidebar-content .chat-user-list li.active h2, .chat-application .sidebar-content .chat-user-list li.active h3, .chat-application .sidebar-content .chat-user-list li.active h4, .chat-application .sidebar-content .chat-user-list li.active h5, .chat-application .sidebar-content .chat-user-list li.active h6 {
  color : #FFFFFF;
}
.chat-application .sidebar-content .chat-user-list li img {
  border : 2px solid #FFFFFF;
}
.chat-application .sidebar-content .card {
  margin-bottom : 0;
}

.chat-application .chat-overlay {
  top : 0;
  right : 0;
  left : 0;
  bottom : 0;
  position : absolute;
  display : block;
  z-index : 2;
  visibility : hidden;
  border-radius : 0.25rem;
}
.chat-application .chat-overlay.show {
  visibility : visible;
  background-color : rgba(0, 0, 0, 0.2);
}

.chat-application .chat-app-window .favorite, .chat-application .chat-app-window .sidebar-toggle {
  cursor : pointer;
}

.chat-application .chat-app-window .user-chats {
  padding : 20px 30px;
  position : relative;
  text-align : center;
  height : calc(100vh - 23.5rem);
  height : calc(var(--vh, 1vh) * 100 - 23.5rem);
}

.chat-application .chat-app-window .start-chat-area, .chat-application .chat-app-window .user-chats {
  background-image : url('../../images/backgrounds/chat-bg.svg');
  background-color : #DFDBE5;
}

.chat-application .chat-app-window .start-chat-area {
  height : calc(100vh - 13rem);
  height : calc(var(--vh, 1vh) * 100 - 13rem);
  display : -webkit-box;
  display : -webkit-flex;
  display : -ms-flexbox;
  display :         flex;
  -webkit-box-pack : center;
  -webkit-justify-content : center;
  -ms-flex-pack : center;
          justify-content : center;
  -webkit-box-align : center;
  -webkit-align-items : center;
  -ms-flex-align : center;
          align-items : center;
  -webkit-box-orient : vertical;
  -webkit-box-direction : normal;
  -webkit-flex-direction : column;
      -ms-flex-direction : column;
          flex-direction : column;
}
.chat-application .chat-app-window .start-chat-area .start-chat-icon, .chat-application .chat-app-window .start-chat-area .start-chat-text {
  background : white;
  box-shadow : 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08) !important;
}
.chat-application .chat-app-window .start-chat-area .start-chat-text {
  border-radius : calc(0.5rem * 4);
  cursor : pointer;
}
.chat-application .chat-app-window .start-chat-area .start-chat-icon {
  border-radius : 50%;
  font-size : 4rem;
  padding : 2rem;
}

.chat-application .chat-app-form {
  padding : 20px 10px;
  background-color : white;
}

.chat-application .chats {
  padding : 0;
}
.chat-application .chats .chat-body {
  display : block;
  margin : 10px 0 0 30px;
  overflow : hidden;
}
.chat-application .chats .chat-body .chat-content {
  text-align : left;
  display : block;
  float : left;
  padding : 0.75rem 1rem;
  margin : 0 0 10px 20px;
  clear : both;
  color : #FFFFFF;
  background : -webkit-linear-gradient(208deg, #7367F0, rgba(115, 103, 240, 0.7));
  background :         linear-gradient(-118deg, #7367F0, rgba(115, 103, 240, 0.7));
  border-radius : 0.5rem;
  box-shadow : 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}
.chat-application .chats .chat-body .chat-content p {
  margin : 0;
}
.chat-application .chats .chat-avatar {
  float : left;
}
.chat-application .chats .chat-left .chat-avatar {
  float : right;
}
.chat-application .chats .chat-left .chat-body {
  margin-left : 0;
  margin-right : 30px;
}
.chat-application .chats .chat-left .chat-content {
  text-align : right;
  float : right;
  margin : 0 20px 10px 0;
  color : #626262;
  background : none;
  background-color : white;
}

.chat-application .user-profile-sidebar {
  border-left : 1px solid #E4E7ED;
  height : calc(100vh - 13rem);
  height : calc(var(--vh, 1vh) * 100 - 13rem);
  width : 400px;
  border-radius : 0.25rem;
  border-top-right-radius : 0;
  border-bottom-right-radius : 0;
  margin : 0;
  background-color : #FFFFFF;
  position : fixed;
  -webkit-transform : translateX(-110%);
      -ms-transform : translateX(-110%);
          transform : translateX(-110%);
  -webkit-transition : all 0.3s ease;
          transition : all 0.3s ease;
  z-index : 6;
  left : 4.2rem;
  bottom : 5.25rem;
  opacity : 0;
}
.chat-application .user-profile-sidebar.show {
  opacity : 1;
  -webkit-transform : translateX(-7%);
      -ms-transform : translateX(-7%);
          transform : translateX(-7%);
  -webkit-transition : all 0.3s ease;
          transition : all 0.3s ease;
}
.chat-application .user-profile-sidebar .user-profile-header {
  display : -webkit-box;
  display : -webkit-flex;
  display : -ms-flexbox;
  display :         flex;
  text-align : center;
  border-bottom : 1px solid rgba(0, 0, 0, 0.06);
}
.chat-application .user-profile-sidebar .user-profile-header .header-profile-sidebar {
  margin : 2rem auto 0.5rem;
}
.chat-application .user-profile-sidebar .user-profile-header .avatar {
  margin-bottom : 1.25rem;
}
.chat-application .user-profile-sidebar .user-profile-header .close-icon {
  position : absolute;
  top : 14px;
  left : 13px;
  font-size : 1.75rem;
  cursor : pointer;
}
.chat-application .user-profile-sidebar .user-profile-sidebar-area {
  height : calc(100vh - 24.25rem);
  height : calc(var(--vh, 1vh) * 100 - 24.25rem);
  position : relative;
}

@media (max-width: 767.98px) {
  .chat-application .chat-app-window {
    height : calc(100% - 132px);
  }
  .chat-application .sidebar-content .sidebar-close-icon {
    visibility : visible;
  }
}

@media (max-width: 575.98px) {
  .chat-application .sidebar-content {
    width : 260px;
    right : -4px !important;
  }
  .chat-application .sidebar-content .chat-fixed-search, .chat-application .sidebar-content .chat-user-list {
    width : 260px;
  }
  .chat-application .chat-profile-sidebar {
    width : 260px;
  }
  .chat-application .user-profile-sidebar {
    width : 260px;
    left : 2.35rem;
  }
}

@media (max-width: 991.98px) {
  .content-right {
    width : 100%;
  }
  .chat-application .sidebar-content {
    -webkit-transform : translateX(110%);
        -ms-transform : translateX(110%);
            transform : translateX(110%);
    -webkit-transition : all 0.3s ease-in-out;
            transition : all 0.3s ease-in-out;
    right : 0;
    position : fixed;
    z-index : 5;
    right : -2px;
  }
  .chat-application .sidebar-content.show {
    -webkit-transform : translateX(-8.5%);
        -ms-transform : translateX(-8.5%);
            transform : translateX(-8.5%);
    -webkit-transition : all 0.3s ease;
            transition : all 0.3s ease;
    display : block;
  }
}

@media (max-width: 349.98px) {
  .chat-application .sidebar-content {
    width : 230px;
    right : -2px !important;
  }
  .chat-application .sidebar-content .chat-fixed-search, .chat-application .sidebar-content .chat-user-list {
    width : 230px;
  }
  .chat-application .chat-profile-sidebar {
    width : 230px;
  }
  .chat-application .user-profile-sidebar {
    width : 230px;
  }
}