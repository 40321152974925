
.seller_profile_page .comment-avatar {
    width: 100%;
    height: auto;
    float: left;
}
.seller_profile_page .blog-comments {
    border: 0;
    padding-bottom: 27px;
}
.seller_profile_page .comment-avatar img {
    border-radius: 100%;
    width: 100%;
    border: 2px solid #47a547;
    padding: 3px;
}
.seller_d {
    text-align: center;
    margin: auto;
}
.seller_p{
    font-size: 20px;
    margin: 0;
}
.seler_p2 {
    font-size: 18px;
}
.f-18 {
    font-size: 18px;
}
.seller_profile_page {
    background-color: #f3f3f3;
   margin-top: .5rem;
    margin-bottom: 30px;
    position: relative;
}
.company_name span {
    margin: 0px 20px 0px 0;
}
.seller_profile_page p {
    color: #000;
}
.follow_btn1 {
    float: right;
    border-radius: 100px;
    padding-left: 30px;
    padding-right: 30px;
}
a.grid_image {
    margin: auto;
    display: table;
}
.gird_img {
    position: relative;
    padding-bottom: 0px;
    margin: auto;
    display: table;
}
.grid_image2 {
    border-bottom: 5px solid #47a547;
    min-width: 100px;
}
.seller_profile_page .blog-comments > ul > li {
    padding-bottom: 0;
}
.seller_profile_page .blog-comments.product-comments {
    padding-top: 0;
    border-bottom: 0;
}
#review_item #list-products {
    height: 1000px;
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
}

.sa-lists-pro{
    height: auto!important;
}
.pm-comment-avatar img{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #ccc;

}
.follow__btn{
    background: #47a547;
    color: #fff;
    margin-bottom: 20px;
    padding: 6px 16px;
    margin-left: 10px;
    border-radius: 30px;
}
.selrof {
    background: #47a547;
    padding: 15px;
    color: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
    text-align: center;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
}
.pro_item.pro_item2{
    margin-bottom: 20px;
}
.selrof .feather.icon-arrow-left {
    position: absolute;
    left: 15px;
    top: 17px;
}
.seller-profile-three {
    position: relative;
}

.seller-profile-three .edit-pen {
    right: auto;
    left: 73px;
    bottom: 23px;
}
.seller-profile-three input {
    position: absolute;
    left: 72px;
    bottom: 22px;
    height: 30px;
    opacity: 0;
    width: 30px;
}
@media screen and (max-width:767px){
    .seller_p {
        font-size: 12px;
        margin: 0;
        padding-right: 10px !important;
    }
    .pm-comment-avatar img {
        height: 60px;
        width: 60px;
        margin-top: 20px;
    }
    .follow__btn {
        margin-left: -4px;
    }
    .seller-profile-three .edit-pen {
        left: 44px;
        bottom: 18px;
        font-size: 8px;
        padding: 5px;
    }
    
.seller-profile-three input {
    left: 44px;
    width: 16px;
    height: 18px;
    bottom: 19px;
}
   
}

/* 18-08-2021 */

.icon-right-arrow{
    font-size: 18px;
    color: #fff;
}

.edit-pen{
    background: #47a547;
    font-size: 15px;
    position: absolute;
    bottom: 16px;
    right: 72px;
    color: #fff;
    padding: 7px;
    border-radius: 50%;
}