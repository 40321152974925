.order-list-header{
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    right: 20px;
    top: 34px;
    left:12px
}
.order-control{
    width: 160px;
    margin-left: 5px;
    height: 35px;
}
.order-span{
    font-size: 11px;
    padding-top: 9px ;
    padding-left:9px;
}
.order-sort{
    width:100px;
    height: 35px;
    margin-left: 0px !important;
}
.order-confirm-btn{
    padding-left: 3px;
    padding-right: 3px;
    height: 35px;
    padding-top: 11px;
}
.order-print{
    padding: 5px;
    font-size: 25px !important;
    color:green
}
.custom-picker .react-datepicker-wrapper input{
    width: 112px;
    height: 35px;
    border: 1px solid #ccc;
    border-radius: 6px;
}
.setting-toggle::after{
    content: '' !important;
}
.set-icon{
    font-size: 26px;
    color: green;
}
.order-table td{
    font-size: 14px;
}
@media screen and (max-width:767px){
/* .order-list-header.custom-picker.sales-report-olh {
    flex-direction: row;
    overflow: auto;
    flex-wrap: nowrap;
} */

.order-list-header.custom-picker.sales-report-olh input {
    min-width: 100px;
}
}

.sale-ellips{
    text-overflow: ellipsis;
    overflow: hidden;
    display: table-cell;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    width: 160px;
    max-width: 250px;
}