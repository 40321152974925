/* body {
    background-color: #FAC945;
    justify-content: center;
    display: flex;
    height: 100vh;
    margin-top: 150px;
    font-size: 17px;
    color: #4B4D54;
    overflow: hidden;
    font-family: 'Source Sans Pro', sans-serif;
  }
  
  .dropdown {
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0,0,0,.1);
    background-color: white;
  }
  .react-datepicker-w100 .react-datepicker-wrapper {
    width: 100%;
}
  .dropdown-header {
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-body {
    padding: 5px;
    border-top: 1px solid #E5E8EC;
    display: none;
  }
  
  .dropdown-body.open {
    display: block;
  }
  
  .dropdown-item {
    padding: 10px;
  }
  
  .dropdown-item:hover {
    cursor: pointer;
  }
  
  .dropdown-item-dot {
    opacity: 0;
    color: #91A5BE;
    transition: all .2s ease-in-out;
  }
  
  .dropdown-item-dot.selected {
    opacity: 1;
  }
  
  .icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
  }
  
  .icon.open {
    transform: rotate(90deg);
  } */
  .react-datepicker {
  width: 22rem;
  }
  .react-datepicker-w100 .react-datepicker-wrapper {
    width: 100%;
}
.no-label-space {
  margin-top: 20px;
}
@media screen and (max-width:767px){
  .no-label-space {
    margin-top: 0px;
  }
}

.prod-name-ellips{
    white-space: normal;
    word-break: break-all;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    height: 38px;
}