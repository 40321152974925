body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.add-edit-note{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 89px;
    display: inline-block
}
body.vertical-layout.vertical-menu-modern  .main-menu.menu-fixed.showw {
  width: 260px;
  left: 0;
  opacity: 1;
  z-index: 99;
  display: block;
}
body.vertical-layout.vertical-menu-modern .main-menu .navigation > li > a > span {
  animation: none !important;
}

.livestream {
  background-image: url("../src/images/loading.gif");
  width: 400px;
  background-repeat: no-repeat;
  background-size: 400px;
  height: 120px;
  background-position: center;
}
.livestream2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  height: 500px;
}
@media (max-width: 1199.98px)
{
.header-navbar .navbar-container ul.nav li a.dropdown-user-link {
    padding: 0.7rem 6px !important;
}
.switch-to {
  margin-top: 20px !important;
  font-size: 10px;
}
.header-navbar .navbar-container ul.nav li a.nav-link-label {
  margin-right: 10px;
}
}